<template>
  <v-container pa-0 ma-1 fluid>
    <v-layout align-center justify-center v-if="loadingUsers">
      <a-spin class="mt-2" tip="Loading..." />
    </v-layout>
    <v-layout v-else row wrap>
      <v-toolbar dense style="background-color: #f6f5f5" flat>
        <a-input
          v-model="search"
          addon-before="Search"
          style="width: 40%"
          class="mt-1 mb-1">
        </a-input>
        <a-select
          v-if="userInfo.name == 'ESTEBAN, JOAN R.' || userInfo.name == 'Klaus Mc Kennen Estrada' || userInfo.name == 'Arnie Cladio'"
          v-model="filterStatus"
          addon-before="Status"
          style="width: 20%"
          class="mt-1 mb-1">
          <a-select-option v-for="item in statusOption" :key="item" :value="item">
            {{showStatusLabel(item)}}            
          </a-select-option>
        </a-select>

        <span class="ml-3 mr-1 font-weight-bold">User Type:</span>
        <a-select
          v-if="!isIchijo"
          v-model="filterAccType"
          addon-before="User Type"
          style="width: 10%"
          class="mt-1 mb-1">
          <a-select-option
            v-for="item in accTypes"
            :key="item"
            v-value="item">
            {{ item }}</a-select-option>
        </a-select>

        <a-select
          v-else
          v-model="filterAccType"
          addon-before="User Type"
          style="width: 10%"
          class="mt-1 mb-1">
          <a-select-option
            v-for="item in ichijoAccTypes"
            :key="item"
            v-value="item">
            {{ item }} </a-select-option>
        </a-select>
        <v-btn
          class="my-custom-btn" 
          style="background-color: #0f3057; color: white"
          small>
          <download-excel
            name="Users"
            :data="usersToExport"
            worksheet="Users Account">
            Export to CSV
            <v-icon>mdi-download</v-icon>
          </download-excel>
        </v-btn>
        <v-btn
          @click="openDocumentController()"
          class="my-custom-btn2" style="background-color: #0f3057; color: white"
          small>
          Document Control
          <v-icon> mdi-file-document-alert</v-icon>
        </v-btn>
        <v-btn
          :loading="isDomainLoaded"
          @click="openRegisteredDomain()"
          class="my-custom-btn3"
          style="background-color: #0f3057; color: white"
          small>
          Registered Domain
          <v-icon> mdi-domain</v-icon>
        </v-btn>

        <v-btn
          v-if="isIchijo"
          class="ml-3"
          style="background-color: #0f3057; color: white"
          small
          @click="openUploadCSVDialog()">
          REGISTER USERS FROM CSV
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <a-button
              v-on="on"
              icon="plus"
              style="background-color: #0f3057; color: white"
              @click="editItem({}, 0)"
              >New</a-button>
          </template>
          <span>New User</span>
        </v-tooltip>
      </v-toolbar>
      <v-col cols="12" class="ml-2 mt-0 mb-0">
        Total Users:{{filteredTotalUsers.length}}
      <span style="margin-left: 20px;" ></span>
        Total search result:{{filteredUsers.length}}
      <span style="margin-left: 20px;" ></span>
        Total Selected:{{selectedItemList.length}}
      <span style="margin-left: 20px;">Sort by Registered Date: </span>
          <v-icon 
              :title="sortAll ? 'Ascending' : 'Descending'"
              color="blue"
              @click="toggleSortDirection">
              {{ sortAll ? 'mdi-sort-calendar-descending' : 'mdi-sort-calendar-ascending' }}
          </v-icon> 
      </v-col>
  <div class="ChangeFont">
   <a-table
        :columns="header"
        :data-source="userPaginatedData"
        bordered
        :scroll="{ y: screenSize - 315 }"
        :pagination="false">
          <span slot='selectAll'>
            Actions
            <v-icon
              class="mr-16 ml-n3 mb-n4"
              :title="selectAll ? 'unselect all' : 'select all'"
              v-if="selectMultiple"
              color="white"
              @click="selectAllItems(selectAll),selectAll=!selectAll"
              >{{selectAll ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline'}} 
            </v-icon>
        </span>
        <span slot='useridsortSet'>
            User ID
            <v-icon
            :title="sortallIDuser ? 'Ascending' : 'Descending'"
            class="ml-n2"
            color="white"
            @click="togglesortUserID"
            >{{sortallIDuser ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
        </span>
        <span slot='namesortSet'>
             Name
            <v-icon
            :title="sortallName ? 'Ascending' : 'Descending'"
            class="ml-n2"
            color="white"
            @click="togglesortName"
            >{{sortallName ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
     
        </span>
        <span slot='branchsortSet'>
             Branch
            <v-icon
            :title="sortallBranch ? 'Ascending' : 'Descending'"
            class="ml-n2"
            color="white"
            @click="togglesortBranch"
            >{{sortallBranch ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
     
        </span>

        <span slot='accounttypesortSet'>
             Account Type
            <v-icon
            :title="sortaccountType ? 'Sort different Account type' : 'Sort different Account type'"
            class="ml-n2"
            color="white"
            @click="togglesortaccountType"
            >{{sortaccountType ? 'mdi-menu-swap-outline' : 'mdi-menu-swap'}}</v-icon>
     
        </span>
        <template slot="hrdInquiry" slot-scope="text">
          <div style="display: flex; justify-content: center;">
            <v-icon v-if="text == true" color="green">mdi-check-bold</v-icon>
            <v-icon v-else color="red">mdi-close-thick</v-icon>
          </div>
        </template>

        <template slot="cadRequest" slot-scope="text">
          <div style="display: flex; justify-content: center">
           <v-icon v-if="text == true " color="green"> mdi-check-bold </v-icon>
           <v-icon v-else color="red"> mdi-close-thick </v-icon>
          </div>
        </template>

        <template slot="ichijoSekkei" slot-scope="text">
          <div style="display: flex; justify-content: center">
           <v-icon v-if="text == true " color="green"> mdi-check-bold </v-icon>
           <v-icon v-else color="red"> mdi-close-thick </v-icon>
          </div>
        </template>
                <template slot="srs" slot-scope="text">
          <div style="display: flex; justify-content: center">
           <v-icon v-if="text == true " color="green"> mdi-check-bold </v-icon>
           <v-icon v-else color="red"> mdi-close-thick </v-icon>
          </div>
        </template>

        <span slot="sortmailAddress">
          Mail Address
            <v-icon
            :title="sortallmailAddress ? 'Ascending' : 'Descending'"
            class="ml-n2"
            color="white"
            @click="togglesortmailAddress"
            >{{sortallmailAddress ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
        </span>
        <a slot="actions" slot-scope="text, record">
          <v-layout row wrap>
            <v-tooltip bottom v-if="filterStatus == 'Active' && !selectMultiple && !record.isGCUser">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-if="filterStatus == 'Active' && !record.isGCUser"
                  v-on="on"
                  @click="editItem(record, 1)"
                  style="color: #0f3057"
                  >mdi-pencil-box</v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom v-if="filterStatus == 'Active' && !selectMultiple && record.isGCUser">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-if="filterStatus == 'Active' && record.isGCUser"
                  v-on="on"
                  @click="editItem(record, 1)"
                  style="color: #0f3057"
                  >mdi-eye</v-icon>
              </template>
              <span>View/Edit Access</span>
            </v-tooltip>
            
            <div class="ml-1"
              v-if="filterStatus == 'Active' && !selectMultiple && !record.isGCUser">
            <v-tooltip bottom >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-if="filterStatus == 'Active'"
                  v-on="on"
                  style="color: #0f3057"
                  @click="deleteItem(record)"
                  >mdi-trash-can</v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
            </div>
            <v-tooltip bottom v-if="filterStatus == 'Inactive'">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-if="filterStatus == 'Inactive'"
                  v-on="on"
                  style="color: #0f3057"
                  @click="retrieve(record)"
                  >mdi-recycle
                </v-icon>
              </template>
              <span>Retrieve</span>
            </v-tooltip>
            <div
              class="ml-3"
              v-if="showPassChanged.includes(userInfo.employeeCode) &&
                record.PassChangedBy &&
                record.PasswordChanged">
              <v-tooltip right color="#008891">
                <template v-slot:activator="{ on }">
                  <v-spacer />
                    <span>
                      <v-icon class="animate-charcter" v-on="on"
                      >mdi-key-change</v-icon>
                    </span>
                </template>
                <span>Edited by: {{ record.PassChangedBy }}</span>
              </v-tooltip>
            </div>
            <v-tooltip bottom v-if="filterStatus == 'Active' && selectMultiple && !record.isGCUser">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-if="filterStatus == 'Active'"
                  v-on="on"
                  style="color: #0f3057"
                  @click="selectItem(record),record.selected=!record.selected"
                  >{{record.selected ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
              </template>
              <span>{{record.selected ? 'unselect' : 'select'}}</span>
            </v-tooltip>
          </v-layout>
        </a>
      </a-table>
</div>
    </v-layout>
      <v-fab-transition>
      <v-btn
          v-if="isIchijo && userInfo.isAdmin && !loadingUsers" 
          @click="selectAllItems(!selectMultiple),
          selectMultiple=!selectMultiple,
          selectAll=false,
          selectedItemList=[]"
          color="blue" dark absolute right fab>
            <v-icon>mdi-delete-sweep</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn 
          v-if="selectMultiple" :disabled="!selectedItemList.length"
          @click="deleteSelectedItems()"
          v-show="hideFloatdelete"
          color="red" dark absolute right fab
          style="margin-right: 65px;" >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-row>
      <v-col cols="3"></v-col>
        <v-col cols="2">
          <v-select
              class="mb-n0 mt-0"
              v-model="displayUserCount"
              :items="pageSizes"
              label="User per Page"
              @change="handlePageSizeChange"
              v-if="!loadingUsers"
              style="margin-left: 60px;">
            </v-select>
          </v-col>
        <v-col cols="1" class="mb-n2 mt-2"> 
              <v-btn 
              color="1e1e1e" dark absolute 
              v-if="!loadingUsers"
                style="margin-left: 10px; width: 150px;">
                {{ displayRecordRange() }}
            </v-btn>
        </v-col>
 

    <v-col cols="3" class="mb-n1 mt-5 ml-15">
      <v-row>
        <v-btn
          @click="userPageNo = 1"
          :disabled="userPageNo === 1"
          style="background-color: black; margin-left: 35px;"
          v-if="!loadingUsers">
          <v-icon color="white">mdi-page-first</v-icon>
        </v-btn>
          <v-pagination
            v-model="userPageNo"
            :length="userPageCount"
            :total-visible="0"
            dark
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            v-if="!loadingUsers">
          </v-pagination>
        <v-btn
          @click="userPageNo = userPageCount"
          :disabled="userPageNo === userPageCount"
          style="background-color: black"
          v-if="!loadingUsers">
          <v-icon color="white">mdi-page-last</v-icon>
        </v-btn>
        </v-row>
      </v-col>
    </v-row>  
    <v-dialog
      max-width="750px"
      v-model="documentControllerDialog"
      persistent
      scrollable>
      <v-card>
        <v-toolbar dense flat style="background-color: #f6f5f5">
          <v-toolbar-title style="font-family: Arial; font-weight: bold">
            Controller
          </v-toolbar-title>
          <v-spacer />
          <v-icon @click="closeDocumentController()">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-btn @click="RegisterNewController()" small class="mt-4 success">
            <v-icon>mdi-plus</v-icon>
            Add Controller
          </v-btn>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" style="font-family: Arial">No</th>
                  <th class="text-left" style="font-family: Arial">
                    Controller title
                  </th>
                  <th class="text-left" style="font-family: Arial">
                    Is show Jiku Shiyo
                  </th>
                  <th class="text-left" style="font-family: Arial">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(controller, i) in listOfControllers" :key="i">
                  <td style="font-family: Arial">{{ i + 1 }}</td>
                  <td style="font-family: Arial">
                    {{ controller.controller_title }}
                  </td>
                  <td style="font-family: Arial">
                    <v-icon color="green" v-if="controller.isShowJikuShiyo">
                      mdi-check
                    </v-icon>
                    <v-icon v-else color="red"> mdi-close </v-icon>
                  </td>
                  <td style="font-family: Arial">
                    <v-btn
                      @click="EditController(controller)"
                      class="primary"
                      small>
                      Edit
                    </v-btn>
                    <v-btn
                      @click="DeleteController(controller, i)"
                      class="error"
                      small>
                      Delete
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="500px"
      v-model="newControllerDialog"
      persistent
      scrollable>
      <v-card>
        <v-toolbar dense flat style="background-color: #f6f5f5">
          <v-toolbar-title style="font-family: Arial; font-weight: bold">
            {{ controllerTitle }}
          </v-toolbar-title>
          <v-spacer />
          <v-icon @click="CloseNewControllerDialog()">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="toAddFramework.controller_title"
              label="Controller Title"
              style="font-family: Arial"
              outlined
              dense
            ></v-text-field>
            <v-checkbox
              v-model="toAddFramework.isShowJikuShiyo"
              style="margin-top: -20px; font-family: Arial"
              label="Is Show Jikugumi for Shiyo"
            ></v-checkbox>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="margin-top: -30px">
          <v-btn @click="SaveUpdatedController()" block class="success">
            <v-icon>mdi-content-save-check</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="750px" v-model="domainDialog" persistent scrollable>
      <v-card>
        <v-toolbar dense flat style="background-color: #f6f5f5">
          <v-toolbar-title style="font-family: Arial; font-weight: bold">
            Registered Domain
          </v-toolbar-title>
          <v-spacer />
          <v-icon @click="closeRegisteredDomain()">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-btn @click="RegisterNewDomain()" small class="success">
              <v-icon>mdi-plus</v-icon>
              Add Domain
            </v-btn>
            <v-text-field
              v-model="strSearchDomain"
              label="Search Domain"
              dense
              outlined
              class="mt-2"
            ></v-text-field>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="font-family: Arial">No</th>
                    <th class="text-left" style="font-family: Arial">Domain</th>
                    <th class="text-left" style="font-family: Arial">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(domain, i) in filteredListOfDomains" :key="i">
                    <td style="font-family: Arial">{{ i + 1 }}</td>
                    <td style="font-family: Arial">{{ domain.DomainName }}</td>

                    <td style="font-family: Arial">
                      <v-btn @click="EditDomain(domain)" class="primary" small>
                        Edit
                      </v-btn>
                      <v-btn
                        @click="DeleteDomain(domain, i)"
                        class="error"
                        small>
                        Delete
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500px" v-model="newDomainDialog" persistent scrollable>
      <v-card>
        <v-toolbar dense flat style="background-color: #f6f5f5">
          <v-toolbar-title style="font-family: Arial; font-weight: bold">
            {{ domainTitle }}
          </v-toolbar-title>
          <v-spacer />
          <v-icon @click="CloseNewDomainDialog()">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="toAdd.DomainName"
              label="Domain"
              style="font-family: Arial"
              outlined
              dense
            ></v-text-field>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="margin-top: -30px">
          <v-btn @click="SaveUpdateDomain()" block class="success">
            <v-icon>mdi-content-save-check</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="userDialog" persistent>
      <v-card>
        <v-toolbar dense flat style="background-color: #f6f5f5">
          <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          <v-spacer />
          <v-icon @click="userDialog = false">mdi-close</v-icon>
        </v-toolbar>
        <v-card class="ma-1 pa-1">
          <v-layout class="pa-1 ma-1">
            <a-input
              addon-before="*User ID"
              v-model="toEdit.UserId"
              style="width: 30%"
              class="mr-1"
              :disabled="gcuserDisabled"
            ></a-input>
            <a-input
              :disabled="gcuserDisabled"
              style="width: 70%; font-family: 'MS Gothic'"
              v-model="toEdit.Name">
              <template #addonBefore>
                <span style="font-family: 'Arial';">*Name</span>
              </template>
            </a-input>
          </v-layout>
          <v-layout class="pa-1 ma-1">
            <a-input
              @blur="checkEmail()"
              addon-before="*E-mail"
              v-model="toEdit.MailAddress"
              style="width: 100%"
              :disabled="gcuserDisabled"
            ></a-input>
          </v-layout>
          <v-layout class="pa-1 ma-1" v-if="dialogTitle == 'Add User'">
            <a-input-password
              addon-before="*Password"
              v-model="toEdit.Password"
              style="width: 100%"
            ></a-input-password>
          </v-layout>
          <v-layout
            v-if="!changePassWord && dialogTitle == 'Edit User'"
            class="pa-1 ma-1"
          >
            <a-button
              type="primary"
              @click="changePassWord = true"
              style="width: 100%"
              >Change Password</a-button
            >
          </v-layout>
          <v-layout
            class="pa-1 ma-1"
            v-if="dialogTitle != 'Add User' && changePassWord"
          >
            <a-input-password
              addon-before="*Old Password"
              @blur="checkOld()"
              v-model="toEdit.oldPass"
              style="width: 100%"
            ></a-input-password>
          </v-layout>
          <v-layout
            class="pa-1 ma-1"
            v-if="dialogTitle != 'Add User' && changePassWord"
          >
            <a-input-password
              :disabled="isNewPassDisabled"
              addon-before="*New Password"
              v-model="toEdit.NewPassword"
              style="width: 100%"
            ></a-input-password>
          </v-layout>
          <v-layout class="pa-1 ma-1">
            <a-input
              v-model="toEdit.BaseName"
              style="width: 100%; font-family: 'Ms Gothic' "
              :disabled="gcuserDisabled">
                <template #addonBefore>
                  <span style="font-family: 'Arial';">Branch</span>
                </template>
              </a-input>
          </v-layout>
            <br>
            <span class="mr-1 mt-1">*User Type:</span>
            <br>
            <a-radio-group v-model="value" @change="onChange" :disabled="gcuserDisabled">
              <a-radio :style="radioStyle" :value="1"> Ichijo User </a-radio>
              <a-radio :style="radioStyle" :value="2"> HRD User </a-radio>
              <a-radio :style="radioStyle" :value="3" disabled> GC User </a-radio>
              <a-radio :style="radioStyle" :value="4"> Administrator </a-radio>
              <a-radio :style="radioStyle" :value="5"> Sales User </a-radio>
            </a-radio-group>
          <br>
          <br>
          <Span>Other System Access</Span>
          <V-layout>
            <v-checkbox class="mr-5 mt-1" label="HRD Inquiry" v-model="toEdit.hrdInquiry"></v-checkbox>
            <v-checkbox class="mr-5 mt-1" label="CAD Request" v-model="toEdit.cadRequest"></v-checkbox>
            <v-checkbox class="mr-5 mt-1" label="Ichijo Sekkei" v-model="toEdit.ichijoSekkei"></v-checkbox>
            <v-checkbox class="mr-5 mt-1" label="SRS" v-model="toEdit.srs"></v-checkbox>
          </V-layout>
          <v-layout class="pa-1 ma-1">
            <v-spacer />
            <a-button
              icon="save"
              style="background-color: #0f3057; color: white"
              @click="SaveUser()"
              >Save</a-button>
          </v-layout>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="uploadCSVDialog"
      height="500px" width="1000px"
      persistent>
      <v-card fluid height="550px" width="1000px">
        <v-card-title>
          Register/Update multiple users
          <v-spacer/>
          <a title="Download Template" :href="encodeURI(csvTemplate)" download="userTemplate.csv">
            <v-btn variant="outline-primary" style="background-color: #213b57; color: white"><v-icon color="white">mdi-download</v-icon>Download CSV Template</v-btn>
          </a>
          <v-icon @click="closeUploadCSVDialog()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <file-pond
            credits="false"
            ref="pond"
            label-idle="Drag or Click to Add CSV"
            v-model="csvFile"
            :onaddfile="handleFilePondInit"
            @removefile="handleFilePondRemove"/>
          <v-card class="pa-2" height="330px" width="100%" style="overflow-y:auto;">
              <v-layout column align-center justify-center style="color: blue;" v-if="csvFileChecking">
              <div>
                  <a-spin class="mt-2" tip="Saving..." />
              </div>
              <div>
                  Current update: {{ this.countUpdate }} Total: {{ this.max }}
              </div>
          </v-layout>
            <v-simple-table
              dense
              v-if="csvUserList.length!=0 && !csvFileChecking && csvUserErrorList.length==0">
              <thead>
                <th class="pl-3 pr-3">User ID</th>
                <th class="pl-3 pr-3">Mail Address</th>
                <th class="pl-3 pr-3">Name</th>
                <th class="pl-3 pr-3">Branch</th>
                <th class="pl-3 pr-3">Account Type</th>
                <th class="pl-3 pr-3">HRD Inquiry</th>
                <th class="pl-3 pr-3">CAD Request</th>
                <th class="pl-3 pr-3">Ichijo Sekkei</th>
                <th class="pl-3 pr-3">Shiyousho Request</th>
                <th class="pl-3 pr-3">Password</th>
                <th class="pl-3 pr-3">Error</th>
              </thead>
              <tbody>
                <tr v-for="(item,index) in csvUserList" :key="index">
                  <td>{{item.UserId}}</td>
                  <td>{{item.MailAddress}}</td>
                  <td>{{item.Name}}</td>
                  <td>{{item.BaseName}}</td>
                  <td>{{item.accType}}</td>
                  <td>{{item.hrdInquiry}}</td>
                  <td>{{item.cadRequest}}</td>
                  <td>{{item.ichijoSekkei}}</td>
                  <td>{{item.srs}}</td>
                  <td>{{item.Password}}</td>
                  <td>{{item.status}}</td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-simple-table
              dense
              v-if="!csvFileChecking && csvUserErrorList.length!=0">
              <thead>
                <th class="pl-3 pr-3">User ID</th>
                <th class="pl-3 pr-3">Mail Address</th>
                <th class="pl-3 pr-3">Name</th>
                <th class="pl-3 pr-3">Branch</th>
                <th class="pl-3 pr-3">Account Type</th>
                <th class="pl-3 pr-3">HRD Inquiry</th>
                <th class="pl-3 pr-3">CAD Request</th>
                <th class="pl-3 pr-3">Ichijo Sekkei</th>
                <th class="pl-3 pr-3">Shiyousho Request</th>
                <th class="pl-3 pr-3">Password</th>
                <th class="pl-3 pr-3">Error</th>
              </thead>
              <tbody>
                <tr v-for="(item,index) in csvUserErrorList" :key="index" style="background-color:pink;">
                  <td>{{item.UserId}}</td>
                  <td>{{item.MailAddress}}</td>
                  <td>{{item.Name}}</td>
                  <td>{{item.BaseName}}</td>
                  <td>{{item.accType}}</td>
                  <td>{{item.hrdInquiry}}</td>
                  <td>{{item.cadRequest}}</td>
                  <td>{{item.ichijoSekkei}}</td>
                  <td>{{item.srs}}</td>
                  <td>{{item.Password}}</td>
                  <td>{{item.status}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-card-text>
        <v-card-actions class="mt-0 pt-0 mr-2">
          <v-btn
            color="primary"
            width="50%"
            @click="saveUsers()"
            :disabled="csvUserList.length==0 || dataexceedLimit==true" >
            Register User
          </v-btn>

          <v-btn
            color="primary"
            width="50%"
            @click="updateUsers()"
            :disabled="csvUserList.length==0 || dataexceedLimit==true">
            Update User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loadingDialog"
      hide-overlay
      persistent
      width="300">
      <v-card
        color="white">
        <v-card-text>
          <span style="color:#0f3057;">
            Deleting Users
          </span>
          <v-progress-linear
            indeterminate
            color="#0f3057"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import md5 from "md5-hex";
import Swal from "sweetalert2";
import moment from "moment";

const header = [
  {
    dataIndex: "UserId",
    key: "UserId",
    width: 110,
    slots:{
      title: 'useridsortSet',
    }
  },
  {
    dataIndex: "Name",
    key: "Name",
    slots:{
      title: 'namesortSet',
    }
  },
  {
    dataIndex: "accType",
    width: 150,
    key: "accType",
    //scopedSlots: { customRender: "accType" },
    slots:{
      title: "accounttypesortSet",
    }
  },
  {
    dataIndex: "MailAddress",
    key: "MailAddress",
    // scopedSlots: { customRender: "MailAddress" },
    slots:{
      title: 'sortmailAddress'
    }

  },
    {
    dataIndex: "BaseName",
    key: "BaseName",
        slots:{
      title: 'branchsortSet',
    }
  },
     {
    title: 'HRD Inquiry',
    dataIndex: 'hrdInquiry',
    key: 'hrdInquiry',
    width: 90,
    scopedSlots: { customRender: "hrdInquiry" },
  },
    {
    title: "CAD Request",
    dataIndex: "cadRequest",
    key: "cadRequest",
    width: 90,
    scopedSlots: { customRender: "cadRequest" },
  },
    {
    title: "Ichijo Sekkei",
    dataIndex: "ichijoSekkei",
    key: "ichijoSekkei",
    width: 90,
    scopedSlots: { customRender: "ichijoSekkei" },
  },
    {
    title: "SRS",
    dataIndex: "srs",
    key: "srs",
    width: 90,
    scopedSlots: { customRender: "srs" },
  },
  {
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
    width: 100,
    slots:{
      title: 'selectAll',
    }
  },
];
export default {
  data() {
    return {
      value: 0,
      radioStyle: {
        display: "inline",
        height: "30px",
        lineHeight: "30px",
      },
      max: 0,
      num: 0,
      countUpdate: 0,
      dataexceedLimit: false,
      page: 1, 
      pageSizes: ["ALL", 10, 20, 100, 500],
      sortAll: false,
      sortallIDuser: false,
      sortallName: false,
      sortallmailAddress: false,
      sortallBranch: false,
      sortaccountType: false,
      accountTypeOrder: ['Admin', 'Ichijo User', 'HRD User', 'Sales User', 'GC User'],
      accountTypeOrderIchijo: ['Admin', 'Ichijo User', 'Sales User'], //NEW FOR ICHIJO ADMIN ACCOUNT
      currentSortIndex: 0,
      displayUserCount: 20, //2024-04-17
      hideFloaticonselected: true, // 2024-04-03
      hideFloatdelete: false, // 2024-04-03
      userPageNo: 1,
      rowUserCount: 20,
      loadingUsers: true,
      statusOption: ["Active", "Inactive"],
      filterStatus: "Active",
      changePassWord: false,
      search: "",
      header,
      allUsers: [],
      allUsersWithPasswords: [],
      userDialog: false,
      dialogTitle: "",
      toEdit: {},
      isNewPassDisabled: true,
      isSaveDisabled: true,
      editIndex: -1,
      accTypes: ["ALL", "Ichijo User", "HRD User", "GC User", "Sales User", "Admin"],
      ichijoAccTypes: ["ALL", "Ichijo User", "Sales User", "Admin"],
      // ichijoAccTypes: ["ALL", "Ichijo User", "HRD User", "GC User", "Admin", "Sales User"],
      filterAccType: "ALL",
      domainDialog: false,
      newDomainDialog: false,
      gcuserDisabled: false,
      domainTitle: "Add Domain",
      toAdd: {
        DomainName: null,
      },
      listOfDomains: [],
      isDomainLoaded: false,
      strSearchDomain: "",
      registeredDomains: [],
      documentControllerDialog: false,
      newControllerDialog: false,
      controllerTitle: "Add Controller",
      toAddFramework: {
        controller_title: null,
        isShowJikuShiyo: false,
      },
      listOfControllers: [],
      showPassChanged: ["00785", "41303", "31869"],
      uploadCSVDialog: false,
      csvFile: null,
      csvUserList: [],
      csvUserErrorList: [],
      csvFileChecking: false,
      csvTemplate: "data:text/csv;charset=SHIFT-JIS,User ID,Mail Address,Name,Branch,Account Type,hrdInquiry,cadRequest,ichijoSekkei,srs,Password\r\n11111,sample1@ichijo.co.jp,sampleName1,sampleBranch1,Ichijo User,true,true,false,true,ichijo\r\n22222,sample2@ichijo.co.jp,sampleName2,sampleBranch2,Admin,true,true,false,admin",
      selectedItemList: [],
      selectAll: false,
      selectMultiple: false,
      loadingDialog: false,
    };
  },
  created() {
    this.loadAllUsers();
    this.LoadAllRegisterDomains();
    this.LoadAllController();
  },
  methods: {
      showStatusLabel(status){
        return status === "Active" ? "Active User" : "Inactive User";

    },
        topscroll(){
      let tables = document.querySelectorAll('.ant-table-body')
      for(let table of tables){
        table.scrollTop = 0
      }
    },
///---------------SORTING Ascending and Descending Section---------------/// 
      toggleSortDirection() {
          this.sortAll = !this.sortAll;
          this.sortByRegisteredDate();
        },
        sortByRegisteredDate() {
          this.allUsers.sort((a, b) => {
            const dateA = a.hasOwnProperty('RegisteredDate') && a.RegisteredDate ? new Date(a.RegisteredDate) : null;
            const dateB = b.hasOwnProperty('RegisteredDate') && b.RegisteredDate ? new Date(b.RegisteredDate) : null;
            if (!dateA && !dateB) {
              return 0; 
            } else if (!dateA) {
              return 1; 
            } else if (!dateB) {
              return -1; 
            }
            if (this.sortAll) {
              return dateB - dateA; 
            } else {
              return dateA - dateB; 
            }
          });
          this.userPageNo =1
        },

      togglesortUserID() {
          this.sortallIDuser = !this.sortallIDuser;
          this.sortByUserId();
        },

        sortByUserId() {
          const naturalCompare = (a, b) => {
            const ax = [], bx = [];
            a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => { ax.push([$1 || Infinity, $2 || ""]) });
            b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => { bx.push([$1 || Infinity, $2 || ""]) });
            while (ax.length && bx.length) {
              const an = ax.shift();
              const bn = bx.shift();
              const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
              if (nn) return nn;
            }
            return ax.length - bx.length;
          }
          const compareFunction = (a, b) => {
            const userIdA = a.UserId || ""; 
            const userIdB = b.UserId || ""; 
            if (userIdA === "" && userIdB === "") return 0;
            if (userIdA === "") return 1; 
            if (userIdB === "") return -1;
            return this.sortallIDuser ? naturalCompare(userIdA, userIdB) : naturalCompare(userIdB, userIdA);
          };
          this.allUsers.sort(compareFunction);
          this.userPageNo = 1
        },
      togglesortName() {
          this.sortallName = !this.sortallName;
          this.sortByName();
        },
      sortByName() {
          const naturalCompare = (a, b) => {
          const ax = [], bx = [];
            a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => { ax.push([$1 || Infinity, $2 || ""]) });
            b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => { bx.push([$1 || Infinity, $2 || ""]) });
                while (ax.length && bx.length) {
                    const an = ax.shift();
                    const bn = bx.shift();
                    const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1], 'ja');
                      if (nn) return nn;
                  }
                      return ax.length - bx.length;
                };
          const compareFunction = (a, b) => {
                    const nameA = a.Name;
                    const nameB = b.Name;
                      return this.sortallName ? naturalCompare(nameA, nameB) : naturalCompare(nameB, nameA);
                };
                    this.allUsers.sort(compareFunction);
                    this.userPageNo = 1
            },

      togglesortmailAddress() {
          this.sortallmailAddress = !this.sortallmailAddress;
          this.sortByMailAddress();
        },
      sortByMailAddress() {
            const naturalCompare = (a, b) => {
            const ax = [], bx = [];
              a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => { ax.push([$1 || Infinity, $2 || ""]) });
              b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => { bx.push([$1 || Infinity, $2 || ""]) });
                  while (ax.length && bx.length) {
                      const an = ax.shift();
                      const bn = bx.shift();
                      const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1], 'ja');
                        if (nn) return nn;
                    }
                        return ax.length - bx.length;
              };
              const compareFunction = (a, b) => {
                  const nameA = a.MailAddress;
                  const nameB = b.MailAddress;
                    return this.sortallmailAddress ? naturalCompare(nameA, nameB) : naturalCompare(nameB, nameA);
              };
                  this.allUsers.sort(compareFunction);
                  this.userPageNo = 1
          },

      togglesortBranch() {
          this.sortallBranch = !this.sortallBranch;
          this.sortByBranch();
        },
      sortByBranch() {
            const naturalCompare = (a, b) => {
            const ax = [], bx = [];
              a.replace(/(\d+)|(\D+)/g, (_, $1, $2) => { ax.push([$1 || Infinity, $2 || ""]) });
              b.replace(/(\d+)|(\D+)/g, (_, $1, $2) => { bx.push([$1 || Infinity, $2 || ""]) });
                  while (ax.length && bx.length) {
                      const an = ax.shift();
                      const bn = bx.shift();
                      const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1], 'ja');
                        if (nn) return nn;
                    }
                        return ax.length - bx.length;
              };
              const compareFunction = (a, b) => {
                  const nameA = a.BaseName || '';
                  const nameB = b.BaseName || '';
                    return this.sortallBranch ? naturalCompare(nameA, nameB) : naturalCompare(nameB, nameA);
          };
              this.allUsers.sort(compareFunction);
              this.userPageNo = 1
          },
          
          togglesortaccountType() {
            if(this.isIchijo){
            this.currentSortIndex = (this.currentSortIndex + 1) % this.accountTypeOrderIchijo.length;
            this.sortaccountType = !this.sortaccountType;
            this.sortByaccountType();
            }
            else{
            this.currentSortIndex = (this.currentSortIndex + 1) % this.accountTypeOrder.length;
            this.sortaccountType = !this.sortaccountType;
            this.sortByaccountType();
            }
          },
          sortByaccountType() {
            if(this.isIchijo){
            const currentAccountType = this.accountTypeOrderIchijo[this.currentSortIndex];
            this.allUsers.sort((a, b) => {
              if (a.accType === currentAccountType) {
                return -1;
              } else if (b.accType === currentAccountType) {
                return 1;
              } else {
                return 0;
              }
            });
            }
            else{
            const currentAccountType = this.accountTypeOrder[this.currentSortIndex];
            this.allUsers.sort((a, b) => {
              if (a.accType === currentAccountType) {
                return -1;
              } else if (b.accType === currentAccountType) {
                return 1;
              } else {
                return 0;
              }
            });
            }
            this.userPageNo = 1
          },
deleteSelectedItems() {
Swal.fire({
  title: "Do you want to delete?",
  icon: "info",
  showDenyButton: true,
  showCancelButton: true,
  confirmButtonText: "Delete",
  denyButtonText: "Permanent Delete"
}).then(async (result) => {
  if (result.isConfirmed) {
    this.loadingDialog = true;
    const promises = this.selectedItemList.map(user => {
      let url = `${this.api}deleteUser/${user}`;
      return axios.get(url).then(() => {
        let index = this.allUsers.findIndex(a => a.MailAddress === user);
        if (index >= 0) {
          this.allUsers.splice(index, 1);
          this.allUsers.push({ ...user, isDelete: true });
        }
      });
    });
    Promise.all(promises).then(() => {
      this.loadingDialog = false;
      this.selectMultiple = false;
      this.selectedItemList = [];
      Swal.fire({
        icon: "success",
        title: "Successfully Deleted!",
        showConfirmButton: false,
        timer: 1800,
      });
    }).catch(error => {
      console.error("Error deleting users:", error);
      this.loadingDialog = false;
    });
  } else if (result.isDenied) {
    Swal.fire({
      title: "This will ALL USER INFORMATION permanently wipe out.",
      input: "text",
      icon: "warning",
      inputPlaceholder: "Type 'Delete' to confirm",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (value !== "Delete") {
          return "Please type 'Delete' to confirm";
        }
      }
    }).then((confirmation) => {
      if (confirmation.value === "Delete") {
        this.loadingDialog = true;
        const promises = this.selectedItemList.map(user => {
          let url = `${this.api}permanentdeleteUser/${user}`;
          return axios.delete(url).then(() => {
            let index = this.allUsers.findIndex(a => a.MailAddress === user);
            if (index >= 0) {
              this.allUsers.splice(index, 1);
            }
          });
        });

        Promise.all(promises).then(() => {
          this.loadingDialog = false;
          this.selectMultiple = false;
          this.selectedItemList = [];
          Swal.fire({
            icon: "success",
            title: "Successfully Permanently Deleted!",
            showConfirmButton: false,
            timer: 1800,
          });
        }).catch(error => {
          console.error("Error permanently deleting users:", error);
          this.loadingDialog = false;
        });
      }
    });
  }
})},

  displayRecordRange() {
    const start = (this.userPageNo - 1) * this.rowUserCount + 1;
    const end = Math.min(this.userPageNo * this.rowUserCount, this.filteredUsers.length);
    return `${start}-${end} of ${this.filteredUsers.length}`;
  },

    selectAllItems(condition){
      if(this.selectAll){
        
        this.hideFloatdelete = this.selectAll
      }
      else{
        this.hideFloatdelete = !this.selectAll
      }
      if(!condition){
        this.selectedItemList = this.filteredUsers.map(r=>{
          return r.MailAddress
        })
      }
      else{
        this.selectedItemList = []
      }
      for(let i=0; i<this.filteredUsers.length; i++){
        this.filteredUsers[i].selected = !condition
      }
    },
    selectItem(record){
      this.selectAll = false;
      if(record.selected){
        let index = this.selectedItemList.indexOf(record.MailAddress)
        this.selectedItemList.splice(index,1)
      }
      else{
        this.selectedItemList.push(record.MailAddress)
      }
    },
   updateUsers(){
      Swal.fire({
        title: "Do you want to update users?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Update",
        denyButtonText: `Don't update`
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.dataexceedLimit = true;
          this.max = this.csvUserList.length
          let url = `${this.api}updateuserMultiple`;
          for await (let user of this.csvUserList){
            let urlCheck = `${this.api}checkUserID?userId=${user.UserId}`;
            axios.get(urlCheck).then(res=>{
              this.num += 1
              let checkDomain = this.registeredDomains.some(a=>user.MailAddress.match(a))
              const isvalidMail = this.isValidMailAddress(user, this.allUsers);
              if(res.data.isDelete==false && checkDomain && isvalidMail && user.UserId == res.data.UserId){
                        user.isRulebookUser = true
                        if(user.accType.toLowerCase()=='admin'){
                          user.accType = 'Ichijo User'
                          user.isAdmin = true
                          user.isSalesUser = false
                          user.isHRDUser = false
                          user.isIchijoUser = false
                          user.isIchijo = true;
                        }
                        else if(user.accType.toLowerCase()=='sales user'){
                          user.accType = 'Sales User'
                          user.isSalesUser = true
                          user.isIchijoUser = false
                          user.isAdmin = false
                          user.isHRDUser = false
                          user.isIchijo = false
                        }
                        else if(user.accType.toLowerCase()=='HRD user' || 
                        user.accType.toLowerCase()=='hrd user'){
                          user.accType = 'HRD User'
                          user.isHRDUser = true
                          user.isIchijoUser = false
                          user.isAdmin = false
                          user.isSalesUser = false
                          user.isIchijo = false
                        }
                        else{
                          user.accType = 'Ichijo User'
                          user.isIchijoUser = true
                          user.isSalesUser = false
                          user.isHRDUser = false
                          user.isIchijo = res.data.isIchijo;
                        }
                        user.Favorites = res.data.Favorites;
                        user.FavoritesMemo = res.data.FavoritesMemo;
                        user.FavoritesSekkei = res.data.FavoritesSekkei;
                        user.FavoritesSekkeiKikaku = res.data.FavoritesSekkeiKikaku;
                        user.FavoritesShiyo = res.data.FavoritesShiyo;
                        ///KEEP ACCESS RIGHT///
                        user.cadRequest = res.data.cadRequest;
                        user.hrdInquiry = res.data.hrdInquiry;
                        user.ichijoSekkei = res.data.ichijoSekkei;

                        user.selected = res.data.selected;
                        user.isDelete = false
                        user.Password = user.Password ? md5(user.Password) : md5('ichijo');
                        user.UpdatedDate = moment().format("YYYY-MM-DD");
                        user.RegisteredDate = res.data.RegisteredDate;
                        user.RegisterBy = res.data.RegisterBy;
                        user.UpdatedBy = this.userInfo.name;
                        
                        //for saving user
                        axios.post(url,user).then(()=>{
                          user.isIchijo = true
                          this.countUpdate++
                        //for delete previous user
                        if(user.MailAddress != res.data.MailAddress){
                        let deleteMailUrl = `${this.api}permanentdeleteUser/${res.data.MailAddress}`;
                        axios.delete(deleteMailUrl);
                        }
                              if (this.countUpdate < this.max) {
                                this.dataexceedLimit = true;
                                this.csvFileChecking = true;
                              } else if (this.countUpdate === this.max) {
                                // this.csvFileChecking = false;
                                // this.dataexceedLimit = false;
                                this.handleCompletion();
                              }
                          if(user.isAdmin){
                            user.accType = 'Admin'
                          }
                          this.allUsers.unshift(user)
                        })
              }
              else if(res.data.isDelete==null && checkDomain && isvalidMail && user.UserId == res.data.UserId){
                          user.isRulebookUser = true
                          if(user.accType.toLowerCase()=='admin'){
                            user.accType = 'Ichijo User'
                            user.isAdmin = true
                            user.isSalesUser = false
                            user.isHRDUser = false
                            user.isIchijoUser = false
                            user.isIchijo = true;
                          }
                          else if(user.accType.toLowerCase()=='sales user'){
                            user.accType = 'Sales User'
                            user.isSalesUser = true
                            user.isIchijoUser = false
                            user.isAdmin = false
                            user.isHRDUser = false
                            user.isIchijo = false
                          }
                          else if(user.accType.toLowerCase()=='HRD user' || 
                          user.accType.toLowerCase()=='hrd user'){
                            user.accType = 'HRD User'
                            user.isHRDUser = true
                            user.isIchijoUser = false
                            user.isAdmin = false
                            user.isSalesUser = false
                            user.isIchijo = false
                          }
                          else{
                            user.accType = 'Ichijo User'
                            user.isIchijoUser = true
                            user.isSalesUser = false
                            user.isHRDUser = false
                            user.isIchijo = res.data.isIchijo;
                          }
                          user.MailAddress = res.data.MailAddress;
                          user.Favorites = res.data.Favorites;
                          user.FavoritesMemo = res.data.FavoritesMemo;
                          user.FavoritesSekkei = res.data.FavoritesSekkei;
                          user.FavoritesSekkeiKikaku = res.data.FavoritesSekkeiKikaku;
                          user.FavoritesShiyo = res.data.FavoritesShiyo;
                          
                          ///KEEP ACCESS RIGHT///
                          user.cadRequest = res.data.cadRequest;
                          user.hrdInquiry = res.data.hrdInquiry;
                          user.ichijoSekkei = res.data.ichijoSekkei;

                          user.selected = res.data.selected;
                          user.isDelete = false
                          user.Password = user.Password ? md5(user.Password) : md5('ichijo');
                          user.UpdatedDate = moment().format("YYYY-MM-DD");
                          user.RegisteredDate = res.data.RegisteredDate;
                          user.RegisterBy = res.data.RegisterBy;
                          user.UpdatedBy = this.userInfo.name;
                          //for saving user
                          axios.post(url,user).then(()=>{
                            user.isIchijo = true
                            this.countUpdate++
                            
                        if(user.MailAddress != res.data.MailAddress){
                        let deleteMailUrl = `${this.api}permanentdeleteUser/${res.data.MailAddress}`;
                        axios.delete(deleteMailUrl);
                        }
                                if (this.countUpdate < this.max) {
                                  this.csvFileChecking = true;
                                  this.dataexceedLimit = true;
                                } else if (this.countUpdate === this.max) {
                                  this.handleCompletion();
                                }
                            if(user.isAdmin){
                              user.accType = 'Admin'
                            }
                            this.allUsers.unshift(user)
                          })
              }
              else{
                let userError = {...user}
                userError.status = ''
                if(!checkDomain){
                  userError.status = 'No domain is registered.'
                }
                if(user.UserId != res.data.UserId){
                  userError.status = userError.status + 'No such User ID found or make sure the ID are match.'
                }
                if(!isvalidMail){
                  userError.status = userError.status + 'The mail address is already taken.'
                }
                this.countUpdate++
                this.csvUserErrorList.push(userError)
                      if (this.countUpdate < this.max) {
                        this.csvFileChecking = true;
                        this.dataexceedLimit = true;
                      } else if (this.countUpdate === this.max) {
                        this.handleCompletion();
                      }               
              }
            })
          }
        } else if (result.isDenied) {
          this.dataexceedLimit = false
          Swal.fire({
            icon: "info",
            title: "Users are not updated",
            showConfirmButton: false,
            timer: 1500,
          });
          return false
        }
        
      });
    },
  
    isValidMailAddress(user, allUsers) {
          const allUsersMailAddresses = allUsers.map(b => b.MailAddress);
          const mailAddressExists = allUsersMailAddresses.includes(user.MailAddress);
          if (!mailAddressExists) {
            return true;
          } else {
            const matchedUser = allUsers.find(b => b.MailAddress === user.MailAddress);
            return matchedUser.UserId === user.UserId;
          }
        },

      handleCompletion() {
        if (this.csvUserErrorList.length > 0) {
          this.csvFileChecking = false;
          this.dataexceedLimit = false;
          Swal.fire({
            icon: "info",
            title: "Some Users are not updated!",
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          this.csvFileChecking = false;
          this.dataexceedLimit = false;
          this.closeUploadCSVDialog();
          Swal.fire({
            icon: "success",
            title: "Successfully Updated!",
            showConfirmButton: false,
            timer: 5000,
          });
        }
      },
       
    saveUsers(){
      Swal.fire({
        title: "Do you want to save users?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.dataexceedLimit = true;
          this.max = this.csvUserList.length
          let url = `${this.api}updateuserMultiple`;
          for await (let user of this.csvUserList){
            let urlCheck = `${this.api}checkUserID?userId=${user.UserId}`;
            axios.get(urlCheck).then(res=>{
              this.num += 1
              let checkDomain = this.registeredDomains.some(a=>user.MailAddress.match(a))
              const isvalidMail = this.isValidMailAddress(user, this.allUsers);
              if(res.data.isDelete==true && checkDomain && isvalidMail){
                user.isRulebookUser = true
                if(user.accType.toLowerCase()=='admin'){
                  user.accType = 'Ichijo User'
                  user.isAdmin = true
                  user.isSalesUser = false
                  user.isHRDUser = false
                  user.isIchijoUser = false;
                  user.isIchijo = true;
                  user.isGCUser = false
                }
                else if(user.accType.toLowerCase()=='sales user'){
                  user.accType = 'Sales User'
                  user.isSalesUser = true
                  user.isIchijoUser = false
                  user.isAdmin = false
                  user.isHRDUser = false
                  user.isIchijo = false
                  user.isGCUser = false
                }
                else if(user.accType.toLowerCase()=='HRD user' || 
                user.accType.toLowerCase()=='hrd user'){
                  user.accType = 'HRD User'
                  user.isHRDUser = true
                  user.isIchijoUser = false
                  user.isAdmin = false
                  user.isSalesUser = false
                  user.isIchijo = false
                  user.isGCUser = false
                }
                else if(user.accType.toLowerCase()=='GC user' || 
                user.accType.toLowerCase()=='gc user'){
                  user.accType = 'GC User'
                  user.isGCUser = true
                  user.isHRDUser = false
                  user.isIchijoUser = false
                  user.isAdmin = false
                  user.isSalesUser = false
                  user.isIchijo = false
                }
                else{
                  user.accType = 'Ichijo User'
                  user.isIchijoUser = true
                  user.isSalesUser = false
                  user.isHRDUser = false
                  user.isGCUser = false
                  user.isIchijo = res.data.isIchijo;
                }
                user.Favorites = res.data.Favorites;
                user.FavoritesMemo = res.data.FavoritesMemo;
                user.FavoritesSekkei = res.data.FavoritesSekkei;
                user.FavoritesSekkeiKikaku = res.data.FavoritesSekkeiKikaku;
                user.FavoritesShiyo = res.data.FavoritesShiyo;
                user.isDelete = false
                user.Password = user.Password ? md5(user.Password) : md5('ichijo');
                user.UpdatedDate = moment().format("YYYY-MM-DD");
                user.RegisteredDate = moment().format("YYYY-MM-DD");
                user.RegisterBy = this.userInfo.name;
                user.UpdatedBy = this.userInfo.name;
                //for saving user
                axios.post(url,user).then(()=>{
                  user.isIchijo = true
                  this.countUpdate++
                      if (this.countUpdate < this.max) {
                        this.csvFileChecking = true;
                        this.dataexceedLimit = true;
                      } else if (this.countUpdate === this.max) {
                        this.handleregisterCompletion();
                      }
                  if(user.isAdmin){
                    user.accType = 'Admin'
                  }
                  this.allUsers.unshift(user)
                })
              }
              else if(res.data.isDelete==null && checkDomain && isvalidMail){
                user.isRulebookUser = true
                if(user.accType.toLowerCase()=='admin'){
                  user.accType = 'Ichijo User'
                  user.isAdmin = true
                  user.isSalesUser = false
                  user.isHRDUser = false
                  user.isIchijo = true;
                }
                else if(user.accType.toLowerCase()=='sales user'){
                 user.accType = 'Sales User'
                  user.isSalesUser = true
                  user.isIchijoUser = false
                  user.isAdmin = false
                  user.isHRDUser = false
                  user.isIchijo = false
                }
                else if(user.accType.toLowerCase()=='HRD user' || 
                user.accType.toLowerCase()=='hrd user'){
                 user.accType = 'HRD User'
                  user.isHRDUser = true
                  user.isIchijoUser = false
                  user.isAdmin = false
                  user.isSalesUser = false
                  user.isIchijo = false
                }
                else{
                  user.isIchijoUser = true
                  user.isSalesUser = false
                  user.isHRDUser = false
                }
                user.isDelete = false
                user.Password = user.Password ? md5(user.Password) : md5('ichijo');
                user.UpdatedDate = moment().format("YYYY-MM-DD");
                user.RegisteredDate = moment().format("YYYY-MM-DD");
                user.RegisterBy = this.userInfo.name;
                user.UpdatedBy = this.userInfo.name;
                axios.post(url,user).then(()=>{
                  user.isIchijo = true
                  this.countUpdate++
                      if (this.countUpdate < this.max) {
                        this.csvFileChecking = true;
                        this.dataexceedLimit = true;
                      } else if (this.countUpdate === this.max) {
                        this.handleregisterCompletion();
                      }
                  if(user.isAdmin){
                    user.accType = 'Admin'
                  }
                  this.allUsers.unshift(user)
                })
              }

              else{
                let userError = {...user}
                userError.status = ''
                if(!checkDomain){
                  userError.status = 'No domain is registered.'
                }
                if(user.UserId == res.data.UserId){
                  userError.status = userError.status + 'The UserID already exists. '   
                }
                if(!isvalidMail){
                  userError.status = userError.status + 'The mail address is already taken. '
                }
                else{
                  userError.status = userError.status + 'Same UserID exists in the Active user lists.'
                }
                this.countUpdate++
                this.csvUserErrorList.push(userError)
                      if (this.countUpdate < this.max) {
                        this.csvFileChecking = true;
                        this.dataexceedLimit = true;
                      } else if (this.countUpdate === this.max) {
                        this.handleregisterCompletion();
                      }         
              }
            })
          }
        } else if (result.isDenied) {
          Swal.fire({
            icon: "info",
            title: "Users are not saved",
            showConfirmButton: false,
            timer: 1500,
          });
          return false
        }
      });
    },
          handleregisterCompletion() {
        if (this.csvUserErrorList.length > 0) {
          this.csvFileChecking = false;
          this.dataexceedLimit = false;
          Swal.fire({
            icon: "info",
            title: "Some Users are not saved!",
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          this.csvFileChecking = false;
          this.dataexceedLimit = false;
          this.closeUploadCSVDialog();
          Swal.fire({
            icon: "success",
            title: "Successfully Added!",
            showConfirmButton: false,
            timer: 5000,
          });
        }
      },
  
  handlePageSizeChange(pageSizes) {
    this.userPageNo = 1; 
    if (pageSizes === 'ALL') {
        this.displayUserCount = 'ALL';
        this.userPageNo = 1; 
        this.rowUserCount = this.filteredUsers.length;
      } else {
        this.rowUserCount = pageSizes;
      }
  },
  
    handleFilePondInit() {
      this.csvUserErrorList = [];
      let file = this.csvFile[0].file;
      
      if (!file.type.match('csv')) {
        Swal.fire({
          icon: "error",
          title: "Invalid File Format!",
        });
        return false;
      }
      let reader = new FileReader();
      reader.readAsText(file, "SHIFT-JIS");
      reader.onload = async (e) => {
        let data = e.target.result.split(/\r\n/ig)
          .map(rec => {
            if (rec.match('"')) {
              let index = rec.match('"').index;
              let password = rec.substring(index, rec.length - 1).replace(/"/g, '');
              // console.log(index, password);
              let splitData = [...rec.split(',', 5), password];
              return splitData;
            }
            return rec.split(',');
          });
        data[0][0] = 'UserId';
        data[0][1] = 'MailAddress';
        data[0][2] = 'Name';
        data[0][3] = 'BaseName';
        data[0][4] = 'accType';
        data[0][5] = 'hrdInquiry';
        data[0][6] = 'cadRequest';
        data[0][7] = 'ichijoSekkei';
        data[0][8] = 'srs';
        data[0][9] = 'Password';

        let userList = [];
            if (data.length > 1001) {
            // if (data.length > 10001) {
              Swal.fire({
                icon: "warning",
                title: "Data Limit Exceeded",
                text: "Only entries with 1000 or fewer records are required.",
              });
              this.$refs.pond.removeFile()
              this.dataexceedLimit=true
            }
        else{
            // for (let i = 1; i < data.length && userList.length < 10000; i++) {
            for (let i = 1; i < data.length && userList.length < 1000; i++) {
              let obj = {};
              for (let j = 0; j < data[i].length; j++) {
                obj[data[0][j].trim()] = data[i][j].trim();
              }
              if (Object.keys(obj).length >= 5) {
                userList.push(obj);
              }
            }
            this.dataexceedLimit=false
            }
        this.csvUserList = userList;
      };
    },

    handleFilePondRemove() {
      this.csvUserList = [];
      this.csvUserErrorList = []
      this.csvFile = null
    },
    openUploadCSVDialog(){
      this.uploadCSVDialog = true
      this.dataexceedLimit = true
    },
    closeUploadCSVDialog(){
      this.uploadCSVDialog = false
      this.csvFile = null
      this.csvUserList = []
      this.csvUserErrorList = []
      this.$refs.pond.removeFile()
      
    },
    async LoadAllController() {
      try {
        const url = `${this.api}documentController/getAllDocumentControllerShiyo`;
        let response = await axios.get(url);
        this.listOfControllers = response.data;
      } catch (err) {
      //remove console.log
      }
    },
    openDocumentController() {
      this.documentControllerDialog = !this.documentControllerDialog;
    },
    closeDocumentController() {
      this.documentControllerDialog = !this.documentControllerDialog;
    },
    RegisterNewController() {
      this.newControllerDialog = !this.newControllerDialog;
    },
    CloseNewControllerDialog() {
      this.toAddFramework = {};
      this.LoadAllController();
      this.controllerTitle = "Add Controller";
      this.newControllerDialog = !this.newControllerDialog;
    },
    EditController(data) {
      this.controllerTitle = "Edit Controller";
      this.toAddFramework = data;
      this.newControllerDialog = !this.newControllerDialog;
    },
    DeleteController(data, index) {
      if (confirm("Are you sure want to delete?") == true) {
        const url = `${this.api}documentController/DeleteDocumentController/${data.id}`;
        axios
          .delete(url)
          .then((res) => {
            if (res.data) {
              Swal.fire({
                icon: "success",
                title: `${data.controller_title} has been deleted!`,
                showConfirmButton: false,
                timer: 1000,
              });
              this.listOfControllers.splice(index, 1);
            }
          })
          .catch((err) => {
            if(err){
            // console.log(err.message);
            }
          });
      }
    },
    SaveUpdatedController() {
      if (this.controllerTitle == "Add Controller") {
        if (this.toAddFramework.controller_title) {
          const url = `${this.api}documentController/InsertNewDocumentControllerShiyo`;
          let toSend = {
            controller_title: this.toAddFramework.controller_title.trim(),
            isShowJikuShiyo: this.toAddFramework.isShowJikuShiyo,
            UpdatedBy: this.userInfo.name,
          };
          axios
            .post(url, toSend)
            .then((res) => {
              if (res.data) {
                Swal.fire({
                  icon: "success",
                  title: `${this.toAddFramework.controller_title} has been added!`,
                  showConfirmButton: false,
                  timer: 1000,
                });
                this.CloseNewControllerDialog();
                this.newControllerDialog = !this.newControllerDialog;
              }
            })
            .catch((err) => {
              if(err){
              // console.log(err.message);
              }
            });
        }
      } else {
        if (this.toAddFramework.controller_title) {
          const url = `${this.api}documentController/UpdateDocumentControllerShiyo`;
          let toSend = {
            id: this.toAddFramework.id,
            isShowJikuShiyo: this.toAddFramework.isShowJikuShiyo,
            controller_title: this.toAddFramework.controller_title,
            UpdatedBy: this.userInfo.name,
          };
          axios
            .put(url, toSend)
            .then((res) => {
              if (res.data) {
                Swal.fire({
                  icon: "success",
                  title: `${this.toAddFramework.controller_title} has been updated!`,
                  showConfirmButton: false,
                  timer: 1000,
                });
                this.CloseNewControllerDialog();
                this.newControllerDialog = !this.newControllerDialog;
              }
            })
            .catch((err) => {
              if(err){
              // console.log(err); remove console log
              }
            });
        }
      }
    },
    SaveUpdateDomain() {
      if (this.domainTitle == "Add Domain") {
        if (this.toAdd.DomainName) {
          if (this.toAdd.DomainName.startsWith("@")) {
            const url = `${this.api}InsertNewDomain`;
            let toSend = {
              DomainName: this.toAdd.DomainName.trim(),
              UpdatedBy: this.userInfo.name,
            };
            axios
              .post(url, toSend)
              .then((res) => {
                if (res.data) {
                  Swal.fire({
                    icon: "success",
                    title: `${this.toAdd.DomainName} has been added!`,
                    showConfirmButton: false,
                    timer: 1000,
                  });
                  this.LoadRegisteredDomain();
                  this.newDomainDialog = !this.newDomainDialog;
                }
              })
              .catch((err) => {
                if(err){
                // console.log(err.message); remove console.log
                }
              });
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.toAdd.DomainName} is invalid domain!`,
              showConfirmButton: false,
              timer: 1000,
            });
            this.toAdd.DomainName = "";
          }
        }
      } else {
        if (this.toAdd.DomainName) {
          if (this.toAdd.DomainName.startsWith("@")) {
            const url = `${this.api}UpdateDomain`;
            let toSend = {
              id: this.toAdd.id,
              DomainName: this.toAdd.DomainName.trim(),
              UpdatedBy: this.userInfo.name,
            };
            axios
              .put(url, toSend)
              .then((res) => {
                if (res.data) {
                  Swal.fire({
                    icon: "success",
                    title: `${this.toAdd.DomainName} has been updated!`,
                    showConfirmButton: false,
                    timer: 1000,
                  });
                  this.LoadRegisteredDomain();
                  this.newDomainDialog = !this.newDomainDialog;
                }
              })
              .catch((err) => {
                if(err){
                // console.log(err.message);
                }
              });
          } else {
            Swal.fire({
              icon: "error",
              title: `${this.toAdd.DomainName} is invalid domain!`,
              showConfirmButton: false,
              timer: 1000,
            });
            this.toAdd.DomainName = "";
          }
        }
      }
    },
    CloseNewDomainDialog() {
      this.toAdd = {};
      this.LoadAllRegisterDomains();
      this.newDomainDialog = !this.newDomainDialog;
      this.domainTitle = "Add Domain";
    },
    DeleteDomain(data, index) {
      if (confirm("Are you sure want to delete?") == true) {
        const url = `${this.api}SoftDeleteDomain`;
        let toSend = {
          id: data.id,
          UpdatedBy: this.userInfo.name,
        };
        axios
          .put(url, toSend)
          .then((res) => {
            if (res.data) {
              Swal.fire({
                icon: "success",
                title: `${data.DomainName} has been deleted!`,
                showConfirmButton: false,
                timer: 1000,
              });
              this.listOfDomains.splice(index, 1);
            }
          })
          .catch((err) => {
            if(err){
            // console.log(err.message);
            }
          });
      }
    },
    RegisterNewDomain() {
      this.toAdd.DomainName = "";
      this.newDomainDialog = !this.newDomainDialog;
    },

    EditDomain(data) {
      this.domainTitle = "Edit Domain";
      this.toAdd = data;
      this.newDomainDialog = !this.newDomainDialog;
    },
    async LoadRegisteredDomain() {
      try {
        const url = `${this.api}getAllUsersDomain`;
        let response = await axios.get(url);
        if (response.data) {
          this.listOfDomains = response.data;
        }
      } catch (err) {
        if(err){
        // console.log(err.message);
        }
      }
    },

    async openRegisteredDomain() {
      try {
        this.isDomainLoaded = true;
        const url = `${this.api}getAllUsersDomain`;
        let response = await axios.get(url);
        if (response.data) {
          this.listOfDomains = response.data;
          this.isDomainLoaded = false;
          this.domainDialog = !this.domainDialog;
        }
      } catch (err) {
        if(err){
        // console.log(err.message);
        }
      }
    },
    closeRegisteredDomain() {
      this.toAdd = {};
      this.LoadAllRegisterDomains();
      this.domainDialog = !this.domainDialog;
    },
    getCsv(item) {
      let url = `${this.api}convertCSV`;
      axios.post(url, item).then((res) => {
        const link = document.createElement("a");
        link.href = res.data;
        link.setAttribute("download", `Users.csv`);
        document.body.appendChild(link);
        link.click();
      });
    },
    onChange(e) {
      if (e.target.value == 1) {
        this.toEdit.isIchijoUser = true;
        this.toEdit.accType="Ichijo User"
        this.toEdit.isAdmin = false;
        this.toEdit.isGCUser = false;
        this.toEdit.isHRDUser = false;
        this.toEdit.isSalesUser = false;
      } else if (e.target.value == 2) {
        this.toEdit.isHRDUser = true;
        this.toEdit.accType="HRD User"
        this.toEdit.isGCUser = false;
        this.toEdit.isIchijoUser = false;
        this.toEdit.isAdmin = false;
        this.toEdit.isSalesUser = false;
      } else if (e.target.value == 3) {
        this.toEdit.isGCUser = true;
        this.toEdit.accType="GC User"
        this.toEdit.isAdmin = false;
        this.toEdit.isIchijoUser = false;
        this.toEdit.isHRDUser = false;
        this.toEdit.isSalesUser = false;
      } else if (e.target.value == 4) {
        this.toEdit.isAdmin = true;
        this.toEdit.accType="Admin"
        this.toEdit.isIchijoUser = false;
        this.toEdit.isHRDUser = false;
        this.toEdit.isGCUser = false;
        this.toEdit.isSalesUser = false;
      } else if (e.target.value == 5) {
        this.toEdit.isSalesUser = true;
        this.toEdit.accType="Sales User"
        this.toEdit.isAdmin = false;
        this.toEdit.isIchijoUser = false;
        this.toEdit.isHRDUser = false;
        this.toEdit.isGCUser = false; 
      } else {
        alert("test");
      }
    },
    loadAllUsers() {
      let url = `${this.api}getAllUsers`;
      axios.get(url).then((res) => {
        this.allUsers = res.data.map((r, i) => {
          r.selected = false
          r.isIchijo = false
          if(r.accType=='Ichijo User'){
            r.isIchijo = true
          }
          r.accType = "";
          if (r.isGCUser) {
            r.accType = "GC User";
          } else if (r.isAdmin) {
            r.accType = "Admin";
          } else if (r.isIchijoUser) {
            r.accType = "Ichijo User";
          } else if (r.isHRDUser) {
            r.accType = "HRD User";
          } else if (r.isSalesUser) {
            r.accType = "Sales User";
          } else {
            r.accType = "";
          }
          r.key = i;
          return r;
        });
        let filteredAccounts = this.allUsers
          .filter((el) => el.isIchijoUser == true)
          .filter((et) => et.Password != "e1e5e9ce07a2f2e0c2dab3eea7143683")
          .filter((eu) => !eu.PassChangedBy)
          .filter((eut) => eut.Password == "4bf31e6f4b818056eaacb83dff01c9b8")
          .map((ey) => {
            return {
              MailAddress: ey.MailAddress,
            };
          });
          if(filteredAccounts){
            //remove console.log
        }
        this.loadingUsers = false;
      });
    },
    editItem(val, i) {
      this.editIndex = -1;
      this.userDialog = true;
      if (i == 0) {
        this.dialogTitle = "Add User";
        this.value = "";
        this.toEdit = {};
        this.toEdit.hrdInquiry = false;
        this.toEdit.cadRequest = false;
        this.toEdit.ichijoSekkei = false;
        this.toEdit.srs = false;
        this.gcuserDisabled = false;
      } else if(val.isGCUser == true){
          this.dialogTitle = "GC User information"
          this.changePassWord =  false;
          this.toEdit = Object.assign({}, val);
          if (this.toEdit.isGCUser) {
          this.value = 3;
        }
          this.gcuserDisabled = true;
          this.editIndex = this.allUsers.indexOf(val);
    
      }
        else {
        this.dialogTitle = "Edit User";
        this.changePassWord = false;
        this.gcuserDisabled = false;
        this.toEdit = Object.assign({}, val);
        if (this.toEdit.isIchijoUser) {
          this.value = 1;
        } else if (this.toEdit.isHRDUser) {
          this.value = 2;
        } else if (this.toEdit.isGCUser) {
          this.value = 3;
        } else if (this.toEdit.isAdmin) {
          this.value = 4;
        } else if (this.toEdit.isSalesUser) {
          this.value = 5;
        } else {
          this.value = 6;
        }
        this.editIndex = this.allUsers.indexOf(val);
      }
    },
    deleteItem(val) {
      Swal.fire({
        title: `Are you sure you want to delete user ${val.Name}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes`,
      }).then((result) => {
        if (result.value) {
          let url = `${this.api}deleteUser/${val.MailAddress}`;
          axios.get(url).then(() => {
            Swal.fire("Deleted!", "User account has been deleted.", "success");
              let index = this.allUsers.findIndex( item =>{
              return item.MailAddress == val.MailAddress
            })
             this.allUsers.splice( index, 1);
             this.allUsers.push( { ...val, isDelete: true } )
          });
        }
      });
    },
    retrieve(val) {
      Swal.fire({
        title: `Are you sure you want to retrieve user ${val.Name}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes`,
      }).then((result) => {
        if (result.value) {
          let url = `${this.api}retrieveUser/${val.MailAddress}`;
          axios.get(url).then(() => {
            Swal.fire(
              "Retrieved!",
              "User account has been retrieved.",
              "success"
            );
            let index = this.allUsers.findIndex( item =>{
              return item.MailAddress == val.MailAddress
            })
            this.allUsers.splice( index, 1);
            this.allUsers.push( { ...val, isDelete: false } )
          });
        }
      });
    },
    async LoadAllRegisterDomains() {
      try {
        const url = `${this.api}getAllUsersDomain`;
        let response = await axios.get(url);
        this.registeredDomains = response.data.map((rec) => {
          return rec.DomainName;
        });
      } catch (err) {
        //remove console.log
      }
    },
    checkEmail() {
      if (this.toEdit.MailAddress.includes("@")) {
        let index = this.toEdit.MailAddress.indexOf("@");
        let cnd = this.toEdit.MailAddress.substring(
          index,
          this.toEdit.MailAddress.length
        );
        if(cnd){
        // console.log(cnd, "cnddddddddddddd"); //remove console log
        }
        if (this.isValidEmail(this.toEdit.MailAddress) == false) {
          Swal.fire({
            icon: "error",
            title: "Invalid Mail Address!",
          });
          this.toEdit.MailAddress = "";
        } else {
          if (this.dialogTitle != "Add User") {
            this.toEdit.oldEmail = this.allUsers[this.editIndex].MailAddress;
          }
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid Mail Address!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.toEdit.MailAddress = "";
      }
    },
    checkOld() {
      if (md5(this.toEdit.oldPass) == this.allUsers[this.editIndex].Password) {
        this.isNewPassDisabled = false;
      } else {
        Swal.fire({
          icon: "error",
          title: "Old password does not match!",
        });
        this.toEdit.oldPass = "";
      }
    },
    SaveUser() {
      let url = `${this.api}saveUser`;
      this.toEdit.isRulebookUser = true;
      if (
        this.toEdit.UserId &&
        this.toEdit.Name &&
        this.toEdit.Password &&
        this.toEdit.MailAddress &&
        this.value != 0
        
      ) {
        if (this.dialogTitle == "Edit User" || this.dialogTitle == "GC User information") {
          this.toEdit.isRulebookUser = true;
          this.toEdit.UpdatedDate = moment().format("YYYY-MM-DD");
          this.toEdit.UpdatedBy = this.userInfo.name;

          delete this.toEdit.key;
          if (this.changePassWord) {
            this.toEdit.Password = md5(this.toEdit.NewPassword);
            delete this.toEdit.oldPass;
            delete this.toEdit.NewPassword;
            this.toEdit.PasswordChanged = true;
            this.toEdit.PassChangedBy = this.userInfo.name;
          } else {
            this.toEdit.Password = this.allUsers[this.editIndex].Password;
          }
          axios.post(url, this.toEdit).then(() => {
            let index = this.allUsers.findIndex( item =>{
              return item.MailAddress == this.toEdit.MailAddress
            })

            this.allUsers[index] = { ...this.toEdit }
            Swal.fire({
              icon: "success",
              title: "Successfully Updated!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.userDialog = false;
            this.value = 0;
          }).then(()=>{
            if(this.editIndex != -1){
              this.allUsers.splice(this.editIndex,1,this.toEdit)
            }
          });
        } else {
          let urlCheck = `${this.api}checkUser?email=${this.toEdit.MailAddress}`;
          axios.get(urlCheck).then((res) => {
            if (res.data == false) {
              this.toEdit.Password = md5(this.toEdit.Password);
              this.toEdit.UpdatedDate = moment().format("YYYY-MM-DD");
              this.toEdit.RegisteredDate = moment().format("YYYY-MM-DD");
              this.toEdit.RegisterBy = this.userInfo.name;
              this.toEdit.UpdatedBy = this.userInfo.name;
              axios.post(url, this.toEdit).then(() => {
            let index = this.allUsers.findIndex( item =>{
              return item.MailAddress == this.toEdit.MailAddress
            })
            this.allUsers[index] = { ...this.toEdit }
                Swal.fire({
                  icon: "success",
                  title: "Successfully Added!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.userDialog = false;
                this.value = 0;
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Mail address user account exists!",
              });
            }
          }).then(()=>{
            this.allUsers.unshift(this.toEdit)
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Required details must be complete!",
        });
      }
    },
  },
  computed: {

    isIchijo(){
      return this.userInfo.isIchijo
    }, 

    filteredListOfDomains() {
      let data = [];
      if (!this.strSearchDomain) {
        data = this.listOfDomains;
      } else {
        data = this.listOfDomains.filter((rec) => {
          return rec.DomainName.toLowerCase().includes(
            this.strSearchDomain.toLowerCase()
          );
        });
      }
      return data;
    },

    usersToExport() {
      if (this.filterAccType == "ALL") {
        return this.filteredUsers
          .filter((r) => {
            return !r.isDelete;
          })
          .map((r, i) => {
            r.key = i;
            let accType = "";
            if (r.isAdmin) {
              accType = "Administrator";
            } else if (r.isIchijoUser) {
              accType = "Ichijo User";
            } else if (r.isGCUser) {
              accType = "GC User";
            } else if (r.isHRDUser) {
              accType = "HRD User";
            } else if (r.isSalesUser) {
              accType = "Sales User";
            } else {
              accType = "";
            }
            return {
              "User ID": r.UserId,
              "Mail Address": r.MailAddress,
              Name: r.Name,
              Branch: r.BaseName,
              "Account Type": accType,
              hrdInquiry: r.hrdInquiry,
              cadRequest: r.cadRequest,
              ichijoSekkei:  r.ichijoSekkei,
              srs:  r.srs
            };
          });
      } else {
        return this.filteredUsers
          .map((r, i) => {
            r.key = i;
            let accType = "";
            if (r.isAdmin) {
              accType = "Administrator";
            } else if (r.isIchijoUser) {
              accType = "Ichijo User";
            } else if (r.isGCUser) {
              accType = "GC User";
            } else if (r.isHRDUser) {
              accType = "HRD User";
            } else if (r.isSalesUser) {
              accType = "Sales User";
            } else {
              accType = "";
            }
            return {
              "User ID": r.UserId,
              "Mail Address": r.MailAddress,
              Name: r.Name,
              Branch: r.BaseName,
              "Account Type": accType,
              hrdInquiry: r.hrdInquiry,
              cadRequest: r.cadRequest,
              ichijoSekkei:  r.ichijoSekkei,
              srs: r.srs
            };
          });
      }
    },
    userPageCount() {
      let l = this.filteredUsers.length,
        s = this.rowUserCount;
      return Math.ceil(l / s);
    },

    userPaginatedData() {
      const start = (this.userPageNo - 1) * this.rowUserCount,
        end = start + this.rowUserCount;
      return this.filteredUsers.slice(start, end);
    },

    screenSize() {
      return this.$vuetify.breakpoint.height;
    },

    filteredUsers() {
        let filteredData = this.allUsers;
        if (this.search) {
          const regex = new RegExp(this.search.trim(), "gi");
          filteredData = filteredData.filter(user => {
            return user.UserId?.match(regex) ||
                  user.Name?.match(regex) ||
                  user.MailAddress?.match(regex) ||
                  user.accType?.match(regex) ||
                  user.BaseName?.match(regex);
          });
        }
        filteredData = filteredData.filter(user => {
          return this.filterStatus === "Active" ? !user.isDelete : user.isDelete;
        });

        if (this.isIchijo) {
          if (this.filterAccType === "ALL") {
            filteredData = filteredData.filter(user => 
              user.isIchijo || user.isIchijoUser || user.isSalesUser || user.isAdmin
            );
            this.topscroll()
          } else if (this.filterAccType === 'Admin') {
            filteredData = filteredData.filter(user => user.isAdmin);
            this.topscroll()
          } else {
            filteredData = filteredData.filter(user => user.accType === this.filterAccType);
            this.topscroll()
          }
        } else if (this.filterAccType !== "ALL") {
          filteredData = filteredData.filter(user => user.accType === this.filterAccType);
        }
        return filteredData;
      },

    filteredTotalUsers() {
      return this.allUsers
        .filter(user => {
            const isIchijoOrIchijoUser = user.isIchijo || user.isIchijoUser || user.isAdmin || user.isSalesUser;
            const filteredHRDAccount = user
            if(this.filterStatus === "Inactive" && this.isIchijo){
              return (user.isDelete) && isIchijoOrIchijoUser;
            }
            if (this.filterStatus === "Active" && this.isIchijo) {
                return (!user.isDelete) && isIchijoOrIchijoUser;
            } else if (!user.isDelete) { 
                return (!user.isDelete) && filteredHRDAccount;
            } else {
                return (!user.isDelete) && filteredHRDAccount;
            }
        });
    },
  },

  watch: {

    userPageNo(){
      let tables = document.querySelectorAll('.ant-table-body')
      for(let table of tables){
        table.scrollTop = 0
      }
    },

    search(val) {
      if (val) {
        this.userPageNo = 1;
      }
    },

    filterAccType(val) {
      if (val) {
        this.userPageNo = 1;
        this.topscroll()
      }
    },

    filterStatus(val) {
      if (val) {
        this.userPageNo = 1;
        this.topscroll()
      }
    },
  },
};
</script>

<style >
@font-face {
  font-family: 'Arial';
  /* src: url('../../public/MS-Gothic_new.ttf'); */
  src: url('../../public/gaigotg.woff');
}
.ChangeFont .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    font-family: 'Arial' !important;
}
.swal2-popup {
  font-family: 'Arial' !important;
}

.my-custom-btn {
    width: 140px; 
    height: 50px;
    font-size: 10px; 
    margin-left: 5px;
}
.my-custom-btn2 {
    width: 170px; 
    height: 50px;
    font-size: 10px;
    margin-left: 20px; 
}
.my-custom-btn3 {
    width: 170px; 
    height: 50px;
    font-size: 10px;
}
.my-custom-btn4 {
    width: 180px; 
    height: 50px;
    font-size: 10px; 
    margin-left: 20px; 
}

.animate-charcter {
  color: #fff;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Josefin Sans", sans-serif;
  background: linear-gradient(
    to right,
    #231557 10%,
    #44107a 50%,
    #ff1361 60%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3.5s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
   
      
</style>
